import { ActionState } from './action-state.model';

export interface Resource {
	id?: number;
	definition?: string;
	key?: string;
	color?: string;
	icon?: string;
	resourceType?: ResourceType;
	order?: number;
	appendFileVersion?: boolean;
	isSimulatorEnabled?: boolean;
}

export interface ResourceExtended extends Resource {
	disabled?: boolean;
	active?: boolean;
	tooltipMessage?: string;
}

export interface ResourceParamValue {
	definition: string | string[];
	resourceType: ResourceType;
}

type Entries<T extends object> = { [K in keyof T]: [K, T[K]] }[keyof T];

function reverseEnum<E extends Record<keyof E, string | number>>(e: E): { [K in E[keyof E]]: Extract<Entries<E>, [any, K]>[0] };
function reverseEnum(e: Record<string | number, string | number>): Record<string | number, string | number> {
	const ret: Record<string | number, string | number> = {};
	Object.keys(e).forEach((k) => (ret[e[k]] = k));
	return ret;
}

function twoWayEnum<E extends Record<keyof E, string | number>>(e: E) {
	return Object.assign(reverseEnum(e), e);
}
/**
 * Interface for the 'Resources' data
 */
export interface ResourcesEntities {
	entities: NormalizedResource;
	actions: ResourceActionTypeState;
}

export enum ResourceType {
	ADDITIONAL_FINANCING_TYPE = 'additional-financing-need-type',
	CITY = 'city',
	CIVIL_STATUS = 'civil-status',
	CLIENT_ROLE = 'client-role',
	COMMENT_TYPE = 'comment-type',
	COMMUNICATION_CONTEXT = 'communication-context', //TODO it is a resource in oper-translations, but not a resource on the BE
	COMMUNICATION_TYPE = 'communication-type',
	COMPANY_TYPE = 'company-type',
	COMFORT_TYPE = 'comfort-type',
	CONTACT_TYPE = 'contact-type',
	CONDITION_OF_PROPERTY = 'condition-of-property',
	CONSTRUCTION_TYPE = 'construction-type',
	COUNTRY = 'country',
	CREDIT_TAKEOVER = 'credit-takeover',
	DECISION_OUTCOME = 'decision-outcome',
	DECISION_TYPE = 'decision-type',
	DOCUMENT_STATUS = 'document-status',
	DOCUMENT_TYPE = 'document-type',
	LIBRARY_DOCUMENT_TYPE = 'library-document-type',
	EMPLOYMENT_CONTRACT_TYPE = 'employment-contract-type',
	EMPLOYMENT_TYPE = 'employment-type',
	HISTORY_LOG_LEVEL = 'history-log-level',
	HISTORY_TYPE = 'history-type',
	ID_DOCUMENT_TYPE = 'id-document-type',
	INCOME_TYPE = 'income-type',
	INSURANCE_TYPE = 'insurance-type',
	LANGUAGE = 'language',
	LIABILITY_TYPE = 'liability-type',
	LIVING_STATUS = 'living-status',
	LOAN_REQUEST_PURPOSE = 'loan-request-purpose',
	LOAN_TYPE = 'loan-type',
	OWNERSHIP_TYPE = 'ownership-type',
	PERIODICITY = 'periodicity',
	PHONE_COUNTRY_CODE = 'phone-country-code',
	PROOF_AUDIENCE = 'proof-audience',
	PROOF_CATEGORY = 'proof-category',
	PROOF_STATUS = 'proof-status',
	REALTY_ACQUISITION_TYPE = 'realty-acquisition-type',
	REALTY_FEATURE = 'realty-feature',
	REALTY_PURPOSE = 'realty-purpose',
	REALTY_TYPE = 'realty-type',
	REALTY_USAGE_TYPE = 'realty-usage-type',
	REGULARITY_TYPE = 'regularity-type',
	REJECTION_REASON_TYPE = 'rejection-reason-type',
	RELATIONSHIP = 'relationship',
	RENOVATION_TYPE = 'renovation-type',
	RESIDENCE_PERMIT = 'residence-permit',
	SECTOR = 'sector',
	SEX = 'sex',
	SIGNATURE_TYPE = 'signature-type',
	STATUS = 'status',
	USER_ROLE = 'user-role',
	VARIABILITY = 'variability',
	TASK_STATUS = 'task-status',
	TASK_CATEGORY = 'task-category',
	TASK_TYPE = 'task-type',
	CLIENT_TITLE = 'client-title',
	LIBRARY_CATEGORY = 'library-category',
	COMMISSION_FILE_STATUS = 'commission-file-status',
	COMMISSION_STATEMENT_STATUS = 'commission-statement-status',
	COMMISSION_PAYMENT_TYPE = 'commission-payment-type',
	COMMISSION_CLASS = 'commission-class',
	FINANCIAL_ASSET_TYPE = 'financial-asset-type',
	FINANCIAL_ASSET_USAGE_TYPE = 'financial-asset-usage-type',
	ACQUISITION_SOURCE = 'acquisition-source',
	INTEGRATOR_ACTION = 'integration-action',
	DATA_CONNECTION_STATUS = 'data-connection-status',
	PERSONALIZED_INFORMATION = 'personalized-information',
	NATIONALITY = 'nationality',
	LOAN_REQUEST_SOURCE = 'loan-request-source',
	REGION = 'region',
	CO_LIVING_PURPOSE = 'co-living-purpose',
	HOME_TYPE = 'home-type',
	CO_LIVING_GROUP_TYPE = 'co-living-group-type',
	SEVERITY_TYPE = 'severity-type',
	PARAMETER_TYPE = 'parameter-type',
	PRODUCT_TYPE = 'product-type',
	EXTERNAL_CALCULATOR_TYPE = 'external-calculator-type',
	VARIABILITY_GROUP_TYPE = 'variability-group-type',
	EDUCATION = 'education',
	SOCIAL_INSURANCE_START_DATE = 'social-insurance-start-date',
	LEGAL_STATUS_TYPE = 'legal-status-type',
	MORTGAGE_SIMULAION_STATUS = 'mortgage-simulation-status',
	MORTGAGE_SIMULAION_SOURCE = 'mortgage-simulation-source',
	PURCHASE_SALE_TYPE = 'purchase-sale-type',
	RENOVATION_VAT_RATE = 'renovation-vat-rate',
	LAND_PURCHASE_TYPE = 'land-purchase-type',
}

export const ResourcePath = {
	nationality: 'country',
};

export const TwoWayResourceType = twoWayEnum(ResourceType);

export type NormalizedResource = Record<ResourceType, Array<Resource>>;
export type ResourceTypeValuesAsKeys = Partial<Record<ResourceType, string>>;
// we need to trick the ts compiler since we are not working directly with simbols but computed values from enum
/* eslint-disable @typescript-eslint/no-empty-interface */
export interface NormalizedResourceInterface extends NormalizedResource {}
export type ResourceActionTypeState = Record<'load', ActionState>;
export type ResourceActionState = Record<keyof NormalizedResourceInterface, ResourceActionTypeState>;
export type PartialNormalizedResource = Partial<NormalizedResource>;
export type TwoWayResourceType = ResourceType | keyof typeof ResourceType;

export function getResourceByDefinition(resources: { [key: string]: Resource[] }, resourceName: string, definition: string) {
	return resources?.[resourceName]?.find((item) => item.definition === definition);
}

export function getResourceById(resources: { [key: string]: Resource[] }, resourceName: string, id: number) {
	return resources?.[resourceName]?.find((item) => item.id === id);
}

export class TranslateContent {
	plainText?: string;
	translateKey?: string;
	translateResource?: Resource;
}
