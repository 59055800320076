import { createFeatureSelector, createSelector } from '@ngrx/store';
import {
	BORROWER_SIMULATOR_APPLICATION_FLOW_KEY,
	BorrowerSimulatorApplicationFlowState,
} from './borrower-simulator-application-flow.reducer';

export const selectState = createFeatureSelector<BorrowerSimulatorApplicationFlowState>(BORROWER_SIMULATOR_APPLICATION_FLOW_KEY);
export const getConfiguration = createSelector(selectState, (state) => state.configuration);
export const getActiveStep = createSelector(selectState, (state) => state.activeStep);
export const getData = createSelector(selectState, (state) => state.data);
export const getDataPoints = createSelector(selectState, (state) => state.dataPoints);
export const getCalculateSimulationActionState = createSelector(selectState, (state) => state.actions.calculateSimulation);
export const getLoadDefaultOffersActionState = createSelector(selectState, (state) => state.actions.loadDefaultOffers);
export const getSteps = createSelector(getConfiguration, (configuration) => configuration?.steps);
export const getSelectedPurpose = createSelector(selectState, (state) => state.selectedPurpose);
export const getSimulation = createSelector(selectState, (state) => state.simulation);
export const getOffers = createSelector(selectState, (state) => state.offers);
