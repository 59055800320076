import { BorrowerSimulatorFeatureConfiguration } from '../../interface/mortgage-simulator-feature.interface';

const featureConfiguration: BorrowerSimulatorFeatureConfiguration = {
	steps: [
		{
			name: 'aboutProject',
			title: 'ç.feature.selfService.steps.aboutProject.title',
			steps: [
				{
					name: 'aboutProject.projectDetails',
					title: 'ç.feature.selfService.steps.projectDetails.title',
					description: 'ç.feature.selfService.steps.projectDetails.description',
					disclaimer: 'ç.feature.selfService.steps.projectDetails.disclaimer',
					formName: 'mortgageSimulatorProjectDetails',
					next: 'aboutProject.yourContribution',
					nextLabel: 'ç.misc.action.next',
				},
				{
					name: 'aboutProject.yourContribution',
					title: 'ç.feature.selfService.steps.yourContribution.title',
					description: 'ç.feature.selfService.steps.yourContribution.description',
					formName: 'mortgageSimulatorYourContribution',
					next: 'financialDetails.income',
					nextLabel: 'ç.misc.action.next',
					back: 'aboutProject.projectDetails',
					backLabel: 'ç.misc.action.back',
				},
			],
		},
		{
			name: 'financialDetails',
			title: 'ç.feature.selfService.steps.financialDetails.title',
			steps: [
				{
					name: 'financialDetails.income',
					title: 'ç.feature.selfService.steps.income.title',
					description: 'ç.feature.selfService.steps.income.description',
					formName: 'mortgageSimulatorIncome',
					back: 'aboutProject.yourContribution',
					backLabel: 'ç.misc.action.back',
					next: 'financialDetails.liability',
					nextLabel: 'ç.misc.action.next',
				},
				{
					name: 'financialDetails.liability',
					title: 'ç.feature.selfService.steps.liability.title',
					description: 'ç.feature.selfService.steps.liability.description',
					formName: 'mortgageSimulatorLiability',
					back: 'financialDetails.income',
					backLabel: 'ç.misc.action.back',
					next: 'personalDetails',
					nextLabel: 'ç.misc.action.next',
				},
			],
		},
		{
			name: 'personalDetails',
			title: 'ç.feature.selfService.steps.personalDetails.title',
			description: 'ç.feature.selfService.steps.personalDetails.description',
			formName: 'mortgageSimulatorPersonalDetails',
			back: 'financialDetails.liability',
			backLabel: 'ç.misc.action.back',
			next: 'simulationReport',
			nextLabel: 'ç.misc.action.next',
		},
		{
			name: 'simulationReport',
			title: 'ç.feature.selfService.steps.simulationReport.title',
			description: 'ç.feature.selfService.steps.simulationReport.description',
			back: 'personalDetails',
			backLabel: 'ç.misc.action.back',
		},
	],
};

export default featureConfiguration;
