import {
	ActionState,
	initialActionState,
	Step,
	StepDataPoint,
	findStepByName,
	generateDataObjectFromStepDataPoints,
	Resource,
	ActionTypes,
	setActionState,
	Simulator,
	BorrowerSimulationDto,
	Offer,
} from '@oper-client/shared/data-model';
import { createRehydrateReducer } from '@oper-client/shared/util-client-storage';
import * as ApplicationFlowActions from './borrower-simulator-application-flow.actions';
import { on } from '@ngrx/store';
import { BorrowerSimulatorFeatureConfiguration } from '../../interface/mortgage-simulator-feature.interface';
import { HttpErrorResponse } from '@angular/common/http';

export const BORROWER_SIMULATOR_APPLICATION_FLOW_KEY = 'borrowerSimulatorApplicationFlow';
export const BORROWER_SIMULATOR_PROJECT_PURPOSE_STEP = 'projectPurpose';

export type BorrowerSimulatorApplicationFlowActionTypes = 'calculateSimulation' | 'loadDefaultOffers';
export type BorrowerSimulatorApplicationFlowActionsState = Record<BorrowerSimulatorApplicationFlowActionTypes, ActionState>;

export interface BorrowerSimulatorApplicationFlowState {
	simulation: Partial<Simulator.Simulation> | null;
	offers: Partial<Offer>[];
	configuration: BorrowerSimulatorFeatureConfiguration | null;
	activeStep: Step | null;
	dataPoints: StepDataPoint | null;
	data: Partial<BorrowerSimulationDto> | null;
	selectedPurpose: Resource | null;
	actions: BorrowerSimulatorApplicationFlowActionsState;
}

export const initialState: BorrowerSimulatorApplicationFlowState = {
	configuration: null,
	dataPoints: null,
	activeStep: null,
	data: null,
	selectedPurpose: null,
	simulation: null,
	offers: [],
	actions: { calculateSimulation: initialActionState, loadDefaultOffers: initialActionState },
};

function setActionStates(
	actionState: BorrowerSimulatorApplicationFlowActionsState,
	action: BorrowerSimulatorApplicationFlowActionTypes,
	actionType: ActionTypes,
	error: HttpErrorResponse = null
): BorrowerSimulatorApplicationFlowActionsState {
	return {
		...actionState,
		[action]: setActionState(actionState[action], actionType, error),
	};
}

export const reducer = createRehydrateReducer(
	BORROWER_SIMULATOR_APPLICATION_FLOW_KEY,
	initialState,
	on(ApplicationFlowActions.calculateSimulation, (state) => ({
		...state,
		actions: setActionStates(state.actions, 'calculateSimulation', ActionTypes.loading),
	})),
	on(ApplicationFlowActions.calculateSimulationSuccess, (state, { result, payload }) => ({
		...state,
		simulation: { ...state.simulation, ...payload, ...result },
		actions: setActionStates(state.actions, 'calculateSimulation', ActionTypes.success),
	})),
	on(ApplicationFlowActions.calculateSimulationFailure, (state, { error }) => ({
		...state,
		actions: setActionStates(state.actions, 'calculateSimulation', ActionTypes.failure, error),
	})),
	on(ApplicationFlowActions.loadDefaultOffers, (state) => ({
		...state,
		offers: initialState.offers,
		actions: setActionStates(state.actions, 'loadDefaultOffers', ActionTypes.loading),
	})),
	on(ApplicationFlowActions.loadDefaultOffersSuccess, (state, { offers }) => ({
		...state,
		offers,
		actions: setActionStates(state.actions, 'loadDefaultOffers', ActionTypes.success),
	})),
	on(ApplicationFlowActions.loadDefaultOffersFailure, (state, { error }) => ({
		...state,
		actions: setActionStates(state.actions, 'loadDefaultOffers', ActionTypes.failure, error),
	})),
	on(ApplicationFlowActions.setConfiguration, (state, { configuration }) => ({
		...state,
		configuration,
	})),
	on(ApplicationFlowActions.updateConfiguration, (state, { changes }) => ({
		...state,
		configuration: { ...state.configuration, ...changes },
	})),
	on(ApplicationFlowActions.setActiveStep, (state, { step }) => ({
		...state,
		activeStep: step,
	})),
	on(ApplicationFlowActions.updateActiveStep, (state, { changes }) => ({
		...state,
		activeStep: { ...state.activeStep, ...changes },
	})),
	on(ApplicationFlowActions.nextStep, (state) => ({
		...state,
		activeStep: findStepByName(state.configuration.steps, state.activeStep?.next),
	})),
	on(ApplicationFlowActions.prevStep, (state) => ({
		...state,
		activeStep: findStepByName(state.configuration.steps, state.activeStep?.back),
	})),
	on(ApplicationFlowActions.setData, (state, { data }) => ({
		...state,
		data,
	})),
	on(ApplicationFlowActions.setDataForStep, (state, { step, data }) => {
		const dataPoints = { ...state.dataPoints, [step]: data };
		const updatedData = { ...initialState.data, ...generateDataObjectFromStepDataPoints<any>(dataPoints) };
		return {
			...state,
			dataPoints,
			data: updatedData,
		};
	}),
	on(ApplicationFlowActions.setStepFormConfiguration, (state, { configuration }) => ({
		...state,
		stepFormConfiguration: configuration,
	})),
	on(ApplicationFlowActions.setSelectedPurpose, (state, { purpose }) => ({
		...state,
		selectedPurpose: purpose,
	})),
	on(ApplicationFlowActions.setSimulation, (state, { simulation }) => ({
		...state,
		simulation,
	})),
	on(ApplicationFlowActions.reset, () => ({
		...initialState,
	})),
	on(ApplicationFlowActions.clearData, (state) => ({
		...state,
		data: initialState.data,
		dataPoints: initialState.dataPoints,
		selectedPurpose: initialState.selectedPurpose,
		simulation: initialState.simulation,
		offers: initialState.offers,
	}))
);
