import { Offer, SelectedBaseProductDiscounts } from '../models/offer.model';
import { Simulator } from '../models/simulator.models';
import { getProductsFromOffers } from './offer-model.utils';

export function getConvertToLoanRequestPayload(
	simulation: Simulator.Simulation,
	offers: Partial<Offer>[],
	selectedDocumentTypes: number[] = []
): Simulator.ConvertToLoanRequestPayload {
	const polishedSimulation = polishSimulationValues(simulation);
	return {
		id: polishedSimulation.id,
		analyst: polishedSimulation.analyst,
		broker: polishedSimulation.broker,
		name: polishedSimulation.name,
		loanPurposes: polishedSimulation.loanPurposes,
		venalValueAfter: polishedSimulation.venalValueAfter,
		venalValueBefore: polishedSimulation.venalValueBefore,
		fairMarketValue: polishedSimulation.fairMarketValue,
		freeUseMortgage: polishedSimulation.freeUseMortgage,
		region: polishedSimulation.region,
		isMainFirstResidence: polishedSimulation.isMainFirstResidence,
		realtyPrice: polishedSimulation.realtyPrice,
		priceOfLand: polishedSimulation.priceOfLand,
		financialPlan: polishedSimulation.calculatedFinancialPlan ?? polishedSimulation.financialPlan,
		borrowersInfo: polishedSimulation.borrowersInfo,
		refinancingAmount: polishedSimulation.refinancingAmount,
		buyOutAmount: polishedSimulation.buyOutAmount,
		additionalNeeds: polishedSimulation.additionalNeeds,
		renovationCosts: polishedSimulation.renovationCosts,
		buildingCosts: polishedSimulation.buildingCosts,
		financialPlanOverriddenFields: polishedSimulation.financialPlanOverriddenFields,
		extraCollateralRealtyType: polishedSimulation.extraCollateralRealtyType,
		extraCollateralRealtyUsageType: polishedSimulation.extraCollateralRealtyUsageType,
		realtyType: polishedSimulation.realtyType,
		realtyUsageType: polishedSimulation.realtyUsageType,
		epcBeforeRenovations: polishedSimulation.epcBeforeRenovations,
		epcAfterRenovations: polishedSimulation.epcAfterRenovations,
		status: polishedSimulation.status,
		offers: offers.map((offer) => ({ items: offer.items ?? [] })),
		financialPlanLoanAmountItems: polishedSimulation.financialPlanLoanAmountItems,
		financialPlanOwnFundItems: polishedSimulation.financialPlanOwnFundItems,
		externalCalculators: polishedSimulation.externalCalculators,
		collateralAmount: polishedSimulation.collateralAmount,
		purchaseSaleType: polishedSimulation.purchaseSaleType,
		selectedDocumentTypes,
	};
}

export function getSearchProductsPayload(
	simulation: Simulator.Simulation,
	selectedDiscounts?: SelectedBaseProductDiscounts[]
): Simulator.SearchProductsPayload {
	const polishedSimulation = polishSimulationValues(simulation);
	return {
		loanPurposes: polishedSimulation.loanPurposes,
		realtyPrice: polishedSimulation.realtyPrice,
		priceOfLand: polishedSimulation.priceOfLand,
		region: polishedSimulation.region,
		isMainFirstResidence: polishedSimulation.isMainFirstResidence,
		venalValueAfter: polishedSimulation.venalValueAfter,
		venalValueBefore: polishedSimulation.venalValueBefore,
		fairMarketValue: polishedSimulation.fairMarketValue,
		freeUseMortgage: polishedSimulation.freeUseMortgage,
		financialPlan: polishedSimulation.calculatedFinancialPlan ?? polishedSimulation.financialPlan,
		financialPlanOverriddenFields: polishedSimulation.financialPlanOverriddenFields,
		borrowersInfo: polishedSimulation.borrowersInfo,
		selectedDiscounts,
		buyOutAmount: polishedSimulation.buyOutAmount,
		refinancingAmount: polishedSimulation.refinancingAmount,
		realtyUsageType: polishedSimulation.realtyUsageType,
		financialPlanLoanAmountItems: polishedSimulation.financialPlanLoanAmountItems,
		financialPlanOwnFundItems: polishedSimulation.financialPlanOwnFundItems,
		externalCalculators: polishedSimulation.externalCalculators,
		collateralAmount: polishedSimulation.collateralAmount,
		epcBeforeRenovations: polishedSimulation.epcBeforeRenovations,
		epcAfterRenovations: polishedSimulation.epcAfterRenovations,
	};
}

export function getLoadOffersPayload(simulation: Simulator.Simulation): Simulator.LoadOffersPayload {
	const polishedSimulation = polishSimulationValues(simulation);
	return {
		loanPurposes: polishedSimulation.loanPurposes,
		realtyPrice: polishedSimulation.realtyPrice,
		priceOfLand: polishedSimulation.priceOfLand,
		region: polishedSimulation.region,
		isMainFirstResidence: polishedSimulation.isMainFirstResidence,
		venalValueAfter: polishedSimulation.venalValueAfter,
		venalValueBefore: polishedSimulation.venalValueBefore,
		fairMarketValue: polishedSimulation.fairMarketValue,
		freeUseMortgage: polishedSimulation.freeUseMortgage,
		financialPlan: polishedSimulation.calculatedFinancialPlan ?? polishedSimulation.financialPlan,
		financialPlanOverriddenFields: polishedSimulation.financialPlanOverriddenFields,
		borrowersInfo: polishedSimulation.borrowersInfo,
		selectedProducts: polishedSimulation.selectedProducts.slice().sort((a, b) => a.creditProvider.id - b.creditProvider.id),
		buyOutAmount: polishedSimulation.buyOutAmount,
		refinancingAmount: polishedSimulation.refinancingAmount,
		realtyUsageType: polishedSimulation.realtyUsageType,
		financialPlanLoanAmountItems: polishedSimulation.financialPlanLoanAmountItems,
		financialPlanOwnFundItems: polishedSimulation.financialPlanOwnFundItems,
		externalCalculators: polishedSimulation.externalCalculators,
		collateralAmount: polishedSimulation.collateralAmount,
		epcBeforeRenovations: polishedSimulation.epcBeforeRenovations,
		epcAfterRenovations: polishedSimulation.epcAfterRenovations,
	};
}

export function getUpdateOfferPayload(simulation: Simulator.Simulation, offer: Pick<Offer, 'id' | 'items'>): Simulator.UpdateOfferPayload {
	const polishedSimulation = polishSimulationValues(simulation);
	return {
		loanPurposes: polishedSimulation.loanPurposes,
		realtyPrice: polishedSimulation.realtyPrice,
		priceOfLand: polishedSimulation.priceOfLand,
		region: polishedSimulation.region,
		isMainFirstResidence: polishedSimulation.isMainFirstResidence,
		venalValueAfter: polishedSimulation.venalValueAfter,
		venalValueBefore: polishedSimulation.venalValueBefore,
		fairMarketValue: polishedSimulation.fairMarketValue,
		freeUseMortgage: polishedSimulation.freeUseMortgage,
		financialPlan: polishedSimulation.calculatedFinancialPlan ?? polishedSimulation.financialPlan,
		financialPlanOverriddenFields: polishedSimulation.financialPlanOverriddenFields,
		borrowersInfo: polishedSimulation.borrowersInfo,
		id: offer.id,
		selectedProducts: getProductsFromOffers([offer]),
		buyOutAmount: polishedSimulation.buyOutAmount,
		refinancingAmount: polishedSimulation.refinancingAmount,
		realtyUsageType: polishedSimulation.realtyUsageType,
		financialPlanLoanAmountItems: polishedSimulation.financialPlanLoanAmountItems,
		financialPlanOwnFundItems: polishedSimulation.financialPlanOwnFundItems,
		externalCalculators: polishedSimulation.externalCalculators,
		collateralAmount: polishedSimulation.collateralAmount,
		epcBeforeRenovations: polishedSimulation.epcBeforeRenovations,
		epcAfterRenovations: polishedSimulation.epcAfterRenovations,
	};
}

export function getCalculateSimulationPayload(simulation: Simulator.Simulation): Simulator.CalculateSimulationPayload {
	const polishedSimulation = polishSimulationValues(simulation);
	return {
		loanPurposes: polishedSimulation.loanPurposes,
		realtyPrice: polishedSimulation.realtyPrice,
		priceOfLand: polishedSimulation.priceOfLand,
		region: polishedSimulation.region,
		isMainFirstResidence: polishedSimulation.isMainFirstResidence,
		venalValueAfter: polishedSimulation.venalValueAfter,
		venalValueBefore: polishedSimulation.venalValueBefore,
		fairMarketValue: polishedSimulation.fairMarketValue,
		freeUseMortgage: polishedSimulation.freeUseMortgage,
		financialPlan: polishedSimulation.financialPlan,
		financialPlanOverriddenFields: polishedSimulation.financialPlanOverriddenFields,
		borrowersInfo: polishedSimulation.borrowersInfo,
		refinancingAmount: polishedSimulation.refinancingAmount,
		buyOutAmount: polishedSimulation.buyOutAmount,
		additionalNeeds: polishedSimulation.additionalNeeds,
		renovationCosts: polishedSimulation.renovationCosts,
		buildingCosts: polishedSimulation.buildingCosts,
		totalMonthlyAmount: polishedSimulation.totalMonthlyAmount,
		realtyUsageType: polishedSimulation.realtyUsageType,
		financialPlanLoanAmountItems: polishedSimulation.financialPlanLoanAmountItems,
		financialPlanOwnFundItems: polishedSimulation.financialPlanOwnFundItems,
		externalCalculators: polishedSimulation.externalCalculators,
		collateralAmount: polishedSimulation.collateralAmount,
		epcBeforeRenovations: polishedSimulation.epcBeforeRenovations,
		epcAfterRenovations: polishedSimulation.epcAfterRenovations,
		monthlyPaymentEstimations: polishedSimulation.monthlyPaymentEstimations,
		purchaseSaleType: polishedSimulation.purchaseSaleType,
	};
}

export function getLoadDefaultOffersPayload(simulation: Simulator.Simulation): Simulator.LoadDefaultOffersPayload {
	const polishedSimulation = polishSimulationValues(simulation);
	return {
		loanPurposes: polishedSimulation.loanPurposes,
		realtyPrice: polishedSimulation.realtyPrice,
		priceOfLand: polishedSimulation.priceOfLand,
		region: polishedSimulation.region,
		realtyUsageType: polishedSimulation.realtyUsageType,
		isMainFirstResidence: polishedSimulation.isMainFirstResidence,
		borrowersInfo: polishedSimulation.borrowersInfo,
		purchaseSaleType: polishedSimulation.purchaseSaleType,
		collateralAmount: polishedSimulation.collateralAmount,
		epcBeforeRenovations: polishedSimulation.epcBeforeRenovations,
		epcAfterRenovations: polishedSimulation.epcAfterRenovations,
		financialPlanLoanAmountItems: polishedSimulation.financialPlanLoanAmountItems,
		financialPlanOwnFundItems: polishedSimulation.financialPlanOwnFundItems,
		externalCalculators: polishedSimulation.externalCalculators,
		venalValueAfter: polishedSimulation.venalValueAfter,
		venalValueBefore: polishedSimulation.venalValueBefore,
		fairMarketValue: polishedSimulation.fairMarketValue,
		freeUseMortgage: polishedSimulation.freeUseMortgage,
		financialPlan: polishedSimulation.calculatedFinancialPlan ?? polishedSimulation.financialPlan,
		financialPlanOverriddenFields: polishedSimulation.financialPlanOverriddenFields,
		buyOutAmount: polishedSimulation.buyOutAmount,
		refinancingAmount: polishedSimulation.refinancingAmount,
	};
}

function polishSimulationValues(simulation: Simulator.Simulation): Simulator.Simulation {
	return {
		...simulation,
		renovationCosts: simulation.renovationCosts?.filter((cost) => !!cost.amount && cost.amount > 0),
	};
}
