import { Inject, Injectable } from '@angular/core';
import * as BorrowerSimulatorApplicationFlowActions from './borrower-simulator-application-flow.actions';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, of, switchMap, takeUntil } from 'rxjs';
import { BORROWER_SIMULATOR_PROJECT_PURPOSE_STEP } from './borrower-simulator-application-flow.reducer';
import { ADVISOR_SIMULATOR_SERVICE, IAdvisorSimulatorService } from '@oper-client/shared/data-access';
import { cancelPendingHttpRequests } from '@oper-client/shared/app/data-access-app';

@Injectable()
export class BorrowerSimulatorApplicationFlowEffects {
	readonly setSelectedPurpose$ = createEffect(() =>
		this.actions$.pipe(
			ofType(BorrowerSimulatorApplicationFlowActions.setSelectedPurpose),
			map(({ purpose }) =>
				BorrowerSimulatorApplicationFlowActions.setDataForStep({
					step: BORROWER_SIMULATOR_PROJECT_PURPOSE_STEP,
					data: { loanPurpose: purpose },
				})
			)
		)
	);

	readonly calculateSimulation = createEffect(() =>
		this.actions$.pipe(
			ofType(BorrowerSimulatorApplicationFlowActions.calculateSimulation),
			switchMap(({ payload }) =>
				this.simulatorService.calculateSimulation(payload).pipe(
					map((result) => BorrowerSimulatorApplicationFlowActions.calculateSimulationSuccess({ result, payload })),
					takeUntil(this.actions$.pipe(ofType(cancelPendingHttpRequests))),
					catchError((error) => of(BorrowerSimulatorApplicationFlowActions.calculateSimulationFailure({ error })))
				)
			)
		)
	);

	readonly loadDefaultOffers$ = createEffect(() =>
		this.actions$.pipe(
			ofType(BorrowerSimulatorApplicationFlowActions.loadDefaultOffers),
			switchMap(({ payload }) =>
				this.simulatorService.loadDefaultOffers(payload).pipe(
					map((offers) => BorrowerSimulatorApplicationFlowActions.loadDefaultOffersSuccess({ offers })),
					takeUntil(this.actions$.pipe(ofType(cancelPendingHttpRequests))),
					catchError((error) => of(BorrowerSimulatorApplicationFlowActions.loadDefaultOffersFailure({ error })))
				)
			)
		)
	);

	constructor(
		private actions$: Actions,
		@Inject(ADVISOR_SIMULATOR_SERVICE) private simulatorService: IAdvisorSimulatorService
	) {}
}
