import { Injectable, Signal } from '@angular/core';
import { select, Store } from '@ngrx/store';
import {
	Step,
	Resource,
	Simulator,
	StepperConfiguration,
	Offer,
	getStepsWhichHaveSavedDataPoints,
	StepData,
	BorrowerSimulationDto,
} from '@oper-client/shared/data-model';
import * as Actions from './borrower-simulator-application-flow.actions';
import * as Selectors from './borrower-simulator-application-flow.selectors';
import { IBorrowerSimulatorFacade, BorrowerSimulatorFeatureConfiguration } from '../../interface/mortgage-simulator-feature.interface';
import { toSignal } from '@angular/core/rxjs-interop';
import { map } from 'rxjs';

@Injectable()
export class BorrowerSimulatorApplicationFlowFacade implements IBorrowerSimulatorFacade {
	constructor(private store: Store<any>) {}

	readonly configuration$ = this.store.pipe(select(Selectors.getConfiguration));
	readonly dataPoints$ = this.store.pipe(select(Selectors.getDataPoints));
	readonly data$ = this.store.pipe(select(Selectors.getData));
	readonly activeStep$ = this.store.pipe(select(Selectors.getActiveStep));
	readonly configuredSteps$ = this.store.pipe(select(Selectors.getSteps));
	readonly selectedPurpose$ = this.store.pipe(select(Selectors.getSelectedPurpose));
	readonly simulation$ = this.store.pipe(select(Selectors.getSimulation));
	readonly offers$ = this.store.pipe(select(Selectors.getOffers));
	readonly loadDefaultOffersActionState$ = this.store.pipe(select(Selectors.getLoadDefaultOffersActionState));
	readonly calculateSimulationActionState$ = this.store.pipe(select(Selectors.getCalculateSimulationActionState));

	getActiveStep(): Signal<Step | null> {
		return toSignal(this.activeStep$);
	}

	getSteps(): Signal<Step[]> {
		return toSignal(this.configuredSteps$);
	}

	getSelectedPurpose(): Signal<Resource> {
		return toSignal(this.selectedPurpose$);
	}

	getCompletedStepNames(): Signal<string[]> {
		return toSignal(this.dataPoints$.pipe(map((dataPoints) => getStepsWhichHaveSavedDataPoints(dataPoints))));
	}

	getSimulation(): Signal<Simulator.Simulation> {
		return toSignal(this.simulation$) as Signal<Simulator.Simulation>;
	}

	getConfiguration?(): Signal<StepperConfiguration> {
		return toSignal(this.configuration$);
	}

	getDefaultOffers(): Signal<Partial<Offer>[]> {
		return toSignal(this.offers$);
	}

	setActiveStep(step: Step): void {
		this.store.dispatch(Actions.setActiveStep({ step }));
	}

	setSelectedPurpose(purpose: Resource): void {
		this.store.dispatch(Actions.setSelectedPurpose({ purpose }));
	}

	setSimulation(simulation: Simulator.Simulation): void {
		this.store.dispatch(Actions.setSimulation({ simulation }));
	}

	updateActiveStep(changes: Partial<Step>) {
		this.store.dispatch(Actions.updateActiveStep({ changes }));
	}

	setConfiguration(configuration: BorrowerSimulatorFeatureConfiguration): void {
		this.store.dispatch(Actions.setConfiguration({ configuration }));
	}

	updateConfiguration(changes: Partial<BorrowerSimulatorFeatureConfiguration>) {
		this.store.dispatch(Actions.updateConfiguration({ changes }));
	}

	nextStep(): void {
		this.store.dispatch(Actions.nextStep());
	}

	previousStep(): void {
		this.store.dispatch(Actions.prevStep());
	}

	setData(data: Partial<BorrowerSimulationDto>): void {
		this.store.dispatch(Actions.setData({ data }));
	}

	setDataForStep<T>(stepData: StepData<T>): void {
		this.store.dispatch(Actions.setDataForStep({ ...stepData }));
	}

	reset(): void {
		this.store.dispatch(Actions.reset());
	}

	clearData(): void {
		this.store.dispatch(Actions.clearData());
	}

	calculateSimulation(payload: Simulator.CalculateSimulationPayload): void {
		this.store.dispatch(Actions.calculateSimulation({ payload }));
	}

	loadDefaultOffers(payload: Simulator.CalculateSimulationPayload): void {
		this.store.dispatch(Actions.loadDefaultOffers({ payload }));
	}
}
