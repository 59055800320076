import { DynamicInputTable, InputField, InputSelect } from '../models/input-types.model';
import { InputBase } from '../models/input-base.model';
import { debounceTimes } from '@oper-client/shared/configuration';
import { BorrowerSimulationDto, PartialNormalizedResource, ResourceType } from '@oper-client/shared/data-model';
import { orderBy } from '@oper-client/shared/util-array';

export default function (formData?: BorrowerSimulationDto, resources?: PartialNormalizedResource): InputBase<any>[] {
	return [
		new DynamicInputTable({
			key: 'mainBorrowerIncomes',
			value: formData?.mainBorrowerIncomes,
			debounceTime: debounceTimes.xxs,
			showAsCard: true,
			cardTitle: 'ç.misc.mainBorrower',
			addRowLabel: 'ç.misc.action.moreIncomes',
			label: 'ç.feature.mortgageSimulator.addIncome',
			required: true,
			columns: [
				new InputSelect({
					key: 'incomeType.id',
					required: true,
					clearable: true,
					label: 'ç.feature.mortgageSimulator.incomeType.label',
					validators: [],
					options: orderBy(resources?.[ResourceType.INCOME_TYPE] || [], 'order'),
					alreadySorted: true,
					class: 'span6',
				}),
				new InputField({
					key: 'monthlyAmount',
					label: 'ç.feature.mortgageSimulator.monthly',
					type: 'number',
					required: true,
					currency: true,
					class: 'span6',
				}),
			],
		}),

		new DynamicInputTable({
			key: 'coBorrowerIncomes',
			value: formData?.coBorrowerIncomes,
			debounceTime: debounceTimes.xxs,
			showAsCard: true,
			cardTitle: 'ç.misc.coBorrower',
			showDeleteButton: true,
			addRowLabel: 'ç.misc.action.moreIncomes',
			label: 'ç.feature.mortgageSimulator.addIncome',
			required: false,
			secondaryAction: true,
			secondaryActionLabel: 'ç.misc.action.addBorrower',
			columns: [
				new InputSelect({
					key: 'incomeType.id',
					required: true,
					clearable: true,
					label: 'ç.feature.mortgageSimulator.incomeType.label',
					validators: [],
					options: orderBy(resources?.[ResourceType.INCOME_TYPE] || [], 'order'),
					alreadySorted: true,
					class: 'span6',
				}),
				new InputField({
					key: 'monthlyAmount',
					label: 'ç.feature.mortgageSimulator.monthly',
					type: 'number',
					required: true,
					currency: true,
					class: 'span6',
				}),
			],
		}),
	];
}
